/**
 * Font Face
 */


@font-face {
  font-family: 'Sofia Pro';
  src: url('../assets/fonts/SofiaProRegular.eot');
  src:
    local('../assets/fonts/Sofia Pro Regular'),
    local('../assets/fonts/SofiaProRegular'),
    url('../assets/fonts/SofiaProRegular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SofiaProRegular.woff2') format('woff2'),
    url('../assets/fonts/SofiaProRegular.woff') format('woff'),
    url('../assets/fonts/SofiaProRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../assets/fonts/SofiaProBold.eot');
  src:
    local('../assets/fonts/Sofia Pro Bold'),
    local('../assets/fonts/SofiaProBold'),
    url('../assets/fonts/SofiaProBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SofiaProBold.woff2') format('woff2'),
    url('../assets/fonts/SofiaProBold.woff') format('woff'),
    url('../assets/fonts/SofiaProBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
