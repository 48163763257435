/**
 * Navbar
 */

$navbar-height-desktop: 80px;
$navbar-height-mobile: 50px;
$navbar-color-bg: transparent;
$navbar-color-link: $color-dark;
$navbar-color-link-hover: $color-link-color;
$navbar-size-toggler: 8px;
$navbar-z-index: 1000;
$navbar-z-index-toggler: 1010;

.navbar {
  background-color: $navbar-color-bg;
  z-index: $navbar-z-index;
}
.navbar__wrapper {
  display: flex;
  align-items: center;
}
.navbar__brand {
  color: $navbar-color-link;
  &:hover {
    text-decoration: none;
    color: $navbar-color-link-hover;
  }
}
.navbar__toggler {
  display: none;
  &:focus {
    outline: none;
  }
}
.navbar__menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.navbar__item {
  margin-bottom: 0;
}
.navbar__link {
  transition: $transition-base;
  color: $navbar-color-link;
  text-decoration: none;
}
.navbar__item--selected .navbar__link {
  color: $navbar-color-link-hover;
}

// Media: Small breakpoint and down
@include media-breakpoint-down(sm) {
  .navbar__wrapper {
    height: $navbar-height-mobile;
  }
  .navbar__collapse,
  .navbar__toggler,
  .navbar__toggler-link {
    transition: $transition-base;
  }
  .navbar__collapse {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: auto;
  }
  .navbar__toggler {
    display: inline-block;
    position: relative;
    margin: 0;
    margin-left: auto;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    width: $navbar-size-toggler * 5;
    height: $navbar-size-toggler * 4;
    cursor: pointer;
    z-index: $navbar-z-index-toggler;
  }
  .navbar__toggler-line {
    display: block;
    position: absolute;
    top: $navbar-size-toggler;
    left: $navbar-size-toggler;
    background-color: black;
    height: 1px;
    width: $navbar-size-toggler * 3;
    &:nth-child(2) {
      top: $navbar-size-toggler * 2;
    }
    &:nth-child(3) {
      top: $navbar-size-toggler * 3;
    }
  }
  .navbar__menu {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: $navbar-color-bg;
    padding: 2rem 0;
  }
  .navbar__item:not(:last-child) {
    margin-bottom: 1rem;
  }
  body.navbar--opened {
    // Prevent vertical scrollbar when navbar is opened on smaller devices
    overflow: hidden;

    .navbar__collapse {
      visibility: visible;
      opacity: 1;
      background-color: rgba(#fff, .8);
    }
    .navbar__toggler-line {
      &:nth-child(1) {
        top: $navbar-size-toggler * 2;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        top: $navbar-size-toggler * 2;
        transform: rotate(-45deg);
      }
    }
    .navbar__toggler {
      &:hover {
        transform: rotate(90deg);
      }
    }
  }
}

// Media: Medium breakpoint and up
@include media-breakpoint-up(md) {
  .navbar__wrapper {
    height: $navbar-height-desktop;
  }
  .navbar__brand {
    margin-right: auto;
  }
  .navbar__menu {
    flex-direction: row;
  }

}

@include media-breakpoint-between(md, xl) {
  .navbar__item:not(:last-child) {
    margin-right: rem(24);
  }
}

@include media-breakpoint-up(hd) {
  .navbar__item:not(:last-child) {
    margin-right: rem(80);
  }
}

// Adding some spacing to the body tag when the navbar is fixed
body {
  @include media-breakpoint-down(sm) {
    padding-top: calc(#{$navbar-height-mobile} + 10px);
  }
}

// Navbar Fixed
.navbar--fixed {
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  transition: $transition-base;

  @include media-breakpoint-up(md) {
    top: 67px;
  }
}

// Navbar transparent when scrolling down
.navbar--fixed-transparent.navbar--scrolled {
  top: 0;
  transition: $transition-base;
  opacity: .25;
  &:hover {
    opacity: 1;
  }
}

// Navbar slides out of view when scrolling down
// and slides back in when scrolling up
.navbar--fixed-toggle {
  transition: $transition-base;
  body.navbar--opened & {
    transform: none;
  }
  &.navbar-hidden {
    transform: translateY(-100%);
  }
  &.navbar-visible {
    transform: translateY(0);
  }
}
