/**
 * howDoWeWork
 */

 .howDoWeWork {
  position: relative;

  &:before {
    @include pseudo;
    background-image: url(../assets/img/oval-howDoWeWork.svg);
    background-repeat: no-repeat;
    width: 849px;
    height: 849px;
    top: -50px;
    left: 100px;
    z-index: -1;
  }
  &:after {
    @include pseudo;
    background-image: url(../assets/img/oval-howDoWeWork_2.svg);
    background-repeat: no-repeat;
    width: 434px;
    height: 434px;
    bottom: 50px;
    right: 200px;
    z-index: -1;
  }

  & h2 {
    text-align: center;
  }

  &-item {
    border-radius: 44px;
    box-shadow: 0 17px 34px 0 rgba(0, 221, 238, .1);
    background-color: #ffffff;
    padding: 1rem;
    position: relative;

    & span {
      font-size: rem(24);
      font-weight: $font-weight-bold;
      line-height: 2.79;
    }

    & h4 {
      line-height: 2.79;
      margin-bottom: rem(13);
    }

    & p {
      line-height: 1.64;
      font-size: rem(14);
    }
  }
}

@include media-breakpoint-down(sm) {
  .howDoWeWork {
    padding-top: rem(60);
    padding-bottom: rem(60);
    
    & h2 {
      margin-bottom: 1rem;
    }
  }

  .howDoWeWork-item {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-between(md,lg) {
  .howDoWeWork {
    padding-top: rem(40);
    padding-bottom: rem(60);
    
    & h2 {
      margin-bottom: 4rem;
    }
  }

  .howDoWeWork-item {
    margin-bottom: 2rem;
  }
}

@include media-breakpoint-up(hd) {
  .howDoWeWork {
    padding-top: rem(140);
    padding-bottom: rem(100);
    
    & h2 {
      margin-bottom: rem(33);
    }

    &-item {     
      min-height: 410px;
      padding: rem(56) rem(40) rem(24);
      margin-bottom: rem(64);
    }
  }

}
