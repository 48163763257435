.whatWeDo {
  position: relative;

  &:before {
    @include pseudo;
    background-image: url(../assets/img/oval-whatWeDo_1.svg);
    background-repeat: no-repeat;
    width: 434px;
    height: 434px;
    top: 400px;
    left: 150px;
    z-index: -1;
  }

  &:after {
    @include pseudo;
    background-image: url(../assets/img/oval-whatWeDo_2.svg);
    background-repeat: no-repeat;
    width: 849px;
    height: 849px;
    bottom: 150px;
    right: 50px;
    z-index: -1;
  }

  & .container {
    position: relative;
  }

  &-item {
    border-radius: 44px;
    box-shadow: 0 17px 34px 0 rgba(0, 221, 238, 0.1);
    background-color: #ffffff;
    position: relative;
    
    & h3 {
      line-height: 2.79;
    }

    & p {
      line-height: 1.44;
    }
  }
}

@include media-breakpoint-down(sm) {
  .whatWeDo {
    padding-top: rem(40);

    & h2 {
      margin-bottom: rem(30);
    }    
  }  
  .whatWeDo-item {
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-between(md, xl) {
  .whatWeDo {
    padding-top: rem(80);

    & h2 {
      margin-bottom: rem(150);
    }    
  }

  .whatWeDo-item {
    margin-bottom: rem(24);
    min-height: 289px;
    padding: 1rem 2rem;
  }
}
@include media-breakpoint-up(hd) {
  .whatWeDo {
    padding-top: rem(80);

    & h2 {
      margin-bottom: rem(150);
    }    
  }

  .whatWeDo-item {
    margin-bottom: rem(34);
    min-height: 289px;
    padding: rem(30) rem(40);
   
    &__mt {
      margin-top: -#{rem(80)};
    }

    &__mb {
      margin-top: rem(80);
      z-index: 4;
    }
  }
}
