.complex {
  position: relative;

  &-content {
    & p {
      font-size: rem(14);
      line-height: 1.64;
    }
  }
}

@include media-breakpoint-down(xl) {
  .complex-icon {
    margin-bottom: 1rem;
    text-align: center;
  }

  .complex-content h3 {
    text-align: center;
  }

  .complex-oval {
    display: none;
  }
}


@include media-breakpoint-up(hd) {
  .complex {
    margin-bottom: rem(100);

    &-oval {
      position: absolute;
      background-image: url(../assets/img/oval-complex.svg);
      background-repeat: no-repeat;
      width: 737px;
      height: 695px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      margin-top: rem(50);
    }
  
    &-bebok {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .complex h2,
  .complex-item {
    margin-bottom: rem(60);
  }

  .complex-item {
    display: flex;
  }

  .complex-content {
    width: calc(100% - 100px);
    margin-left: 10px;
    margin-top: 30px;
  }
}
