/**
 * Blockquote
 */

blockquote {
  p {
    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
  }
}
