.dots {
  position: absolute;
  z-index: 2;

  &__01 {
    top: -150px;
    right: -150px;
  }
}

@include media-breakpoint-down(hd) {
  .dots {

    &__01 {
      top: 0;
      right: 0;
    }
    &__02 {
      top: -85px;
      right: 50px;
    }
    &__03 {
      display: none;
    }
    &__04 {
      display: none;
    }
    &__05 {
      top: -35px;
      left: 50px;
    }
    &__06 {
      top: 110px;
      left: 100px;
    }
    &__07 {
      bottom: 20px;
      right: 10px;
    }
    &__08 {
      bottom: 180px;
      right: -70px;
    }
  }
}

@include media-breakpoint-up(md) {
  .dots {

    &__01 {
      top: -150px;
      right: -150px;
    }
    &__02 {
      top: -65px;
      right: 50px;
    }
    &__03 {
      top: 365px;
      left: 70px;
    }
    &__04 {
      top: 425px;
      left: 150px;
    }
    &__05 {
      top: 5px;
      right: 150px;
    }
    &__06 {
      top: 150px;
      left: 150px;
    }
    &__07 {
      bottom: 5px;
      left: 200px;
    }
    &__08 {
      bottom: 180px;
      right: -70px;
    }
  }
}

