.contact {
  position: relative;
  overflow: hidden;
}

.contactWrap {
  position: relative;
  border-radius: 44px;
  box-shadow: 0 17px 34px 0 rgba(0, 221, 238, .1);
  background-color: #ffffff;
  margin-bottom: rem(30);


  &:before {
    @include pseudo;
    background-image: url(../assets/img/oval-contact_1.svg);
    background-repeat: no-repeat;
    width: 983px;
    height: 888px;
    top: -150px;
    left: -300px;
    z-index: -1;
  }

  &:after {
    @include pseudo;
    background-image: url(../assets/img/oval-contact_2.svg);
    background-repeat: no-repeat;
    width: 575px;
    height: 575px;
    top: -150px;
    right: -150px;
    z-index: -1;
  } 

  & > div:first-child {
    h3 {
      margin-top: rem(50);
    }
  }
  & > div:last-child {
    h3 {
      margin-bottom: rem(24);

      & span {
        color: $color-primary;
      }
    }
  }

  & p {
    line-height: 1.57;
    font-size: rem(21);
  }
}

.contact-copyrights a {
  color: $color-text;
}

@include media-breakpoint-down(hd) {
  .contact {
    padding-top: rem(40);

    & h2 {
      margin-bottom: rem(50);
    }    
  }

  .contactWrap { 
    padding: 1rem;

    & > div:nth-of-type(1) {
      margin-bottom: 1rem;
    }
  }
}


@include media-breakpoint-between(md, xl) {
  .contact {
    padding-top: rem(60);

    & h2 {
      margin-bottom: rem(60);
    }    
  }

  .contactWrap { 
    padding: 2rem;
  }
}

@include media-breakpoint-up(hd) {
  .contact {
    padding-top: rem(80);

    & h2 {
      margin-bottom: rem(150);
    }    
  }

  .contactWrap { 
    display: flex;
    justify-content: space-between;
    padding: rem(70) rem(65) rem(55);

    & > div:nth-of-type(1) {
      width: 30%;
    }
    & > div:nth-of-type(2) {
      width: 65%;
    }

    & button[type="submit"] {
      float: right;
    }
  }

  .contact-copyrights {
    text-align: right;
  }
}

.js-validate-error-label {
  font-size: rem(14);
  margin-bottom: rem(8);
}

.contactFormMsg {
  text-align: center;
  padding: 4rem 0 10rem;

  @include media-breakpoint-up(md) {
    padding: 4rem 0 20rem;
  }
}
