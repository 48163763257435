.bebok {
  position: absolute;
  z-index: 3;
}

@include media-breakpoint-down(sm) {
  .bebok-welcome__purple {
    transform: scale(.6);
    top: -20px;
    right: 0;
  }

  .bebok-welcome__orange {
    display: none;
  }
  .bebok-whatWeDo__purple {
    top: -#{rem(40)};
    left: -#{rem(40)};
    transform: scale(.4);
    z-index: -1;
  }

  .bebok-whatWeDo__blue {
    display: none;
  }

  .bebok-contact__purple {
    top: -#{rem(90)};
    transform: scale(.4) scaleX(-1);
    right: 0;
  }
  .bebok-contact__green {
    display: none;
  }
}

@include media-breakpoint-between(md, xl) {
  .bebok-welcome__purple {
    transform: scale(.6);
    top: -50px;
    left: 0;
  }

  .bebok-welcome__orange {
    top: 130px;
    right: 0;
  }

  .bebok-whatWeDo__purple {
    top: 0;
    transform: scale(.6);
    left: 0;
  }

  .bebok-whatWeDo__blue {
    top: 100px;
    right: -150px;
    z-index: -1;
  }

  .bebok-contact__purple {
    top: -#{rem(110)};
    transform: scale(.6) scaleX(-1);
    right: rem(10);
  }
  .bebok-contact__green {
    display: none;
  }
}

@include media-breakpoint-up(hd) {

  .bebok-welcome__purple {
    top: -90px;
    left: -10px;
  }

  .bebok-welcome__orange {
    top: 130px;
    left: 290px;
  }

  .bebok-whatWeDo__purple {
    top: 50%;
    transform: translateY(-50%) scale(.6);
    left: -#{rem(100)};
    margin-top: rem(50);
  }

  .bebok-whatWeDo__blue {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: rem(80);
    margin-left: rem(78);
  }

  .bebok-contact__purple {
    top: -#{rem(90)};
    transform: scale(.6) scaleX(-1);
    right: rem(30);
  }
  .bebok-contact__green {
    bottom: -#{rem(50)};
    left: rem(190);
    display: block;
  }
}
