.loading {
  &:before {
    @include pseudo($position: fixed);
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
  }

  &:after {
    @include pseudo($position: fixed);
    z-index: 10000;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 10px solid darken($color-primary, 10%);
    border-left-color: #fff;
    animation: loaderAnim 0.8s linear infinite forwards;
  }
}

.hidden {
  opacity: 0;
}

@keyframes loaderAnim {
	to {
		transform: rotate(360deg);
	}
}
