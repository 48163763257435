/**
 * Opacity
 */

.opacity-0 {
  opacity: 0;
  transition: opacity .25s;
}

.opacity-25 {
  opacity: .25;
  transition: opacity .25s;
}

.opacity-50 {
  opacity: .5;
  transition: opacity .25s;
}

.opacity-75 {
  opacity: .75;
  transition: opacity .25s;
}

.opacity-100 {
  opacity: 1;
  transition: opacity .25s;
}

// .hide\@md {
//   display: none;
// }
