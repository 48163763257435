/**
  * Px to rem
  * https://github.com/nicholasruggeri/px-to-rem-sass-mixin
  *
  * Usage
  * font-size: rem(20)
  * margin: rem(40) rem(40);
 */

@function strip-units($value) {
  @return ($value / ($value * 0 + 1));
}
@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  $base: 16px;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1rem;
}
