/*!
 * Project: Project Name
 * Author: Name
 * E-mail: E-mail
 * Website:
 */

/**
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    **************   1. Settings
 *     ************    2. Tools
 *      **********     3. Generic
 *       ********      4. Elements
 *        ******       5. Objects
 *         ****        6. Components
 *          **         7. Utilities
 *
 * 1. Settings.........Global configuration and variables.
 * 2. Tools............Functions and mixins.
 * 3. Generic..........Ground zero styles.
 *                     (normalizing.css, box-sizing etc...)
 * 4. Elements.........Unclassed (bare) HTML element
 *                     (like H1, Ul, A etc...)
 * 5. Objects..........Common non-cosmetic structural design patterns.
 *                     e.g containers, rows, grids, colums etc...
 * 6. Components.......Specific cosmetic elements of UI.
 *                     e.g. buttons, forms, header, footer etc...
 * 7. Utilities........Helpers and overrides.
 *
 *    Modules..........Multi-part components.
 *                     e.g. Navbar (HTML, CSS and JS).
 *    Shame............All the CSS, hacks and things we are not proud of.
 */

@import "settings/_colors.scss";
@import "settings/_global.scss";
@import "settings/_spacing.scss";
@import "settings/_transitions.scss";
@import "settings/_typography.scss";
@import "tools/_aspect-ratio.scss";
@import "tools/_center.scss";
@import "tools/_clearfix.scss";
@import "tools/_font-size.scss";
@import "tools/_list-inline.scss";
@import "tools/_list-unstyled.scss";
@import "tools/_max-width-center.scss";
@import "tools/_overlay.scss";
@import "tools/_pseudo.scss";
@import "tools/_rem.scss";
@import "tools/_size.scss";
@import "tools/_space.scss";
@import "tools/_triangle.scss";
@import "generic/_box-sizing.scss";
@import "generic/_font-face.scss";
@import "generic/_normalize.scss";
@import "generic/_print.scss";
@import "elements/_blockquote.scss";
@import "elements/_document.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_media.scss";
@import "elements/_paragraphs.scss";
@import "elements/_tables.scss";
@import "objects/_bootstrap.grid.scss";
@import "objects/_list-inline.scss";
@import "objects/_list-unstyled.scss";
@import "components/_buttons.scss";
@import "components/_headings.scss";
@import "components/_inputs.scss";
@import "utilities/_aspect-ratio.scss";
@import "utilities/_background-size.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_colors.scss";
@import "utilities/_font-family.scss";
@import "utilities/_font-size.scss";
@import "utilities/_font-weight.scss";
@import "utilities/_opacity.scss";
@import "utilities/_overflow.scss";
@import "utilities/_spacing.scss";
@import "utilities/_text-alignment.scss";
@import "utilities/_text-style.scss";
@import "utilities/_visibility.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/anime/anime.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/beboks/bebok.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/complex/complex.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/contact/contact.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/dots/dots.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/howDoWeWork/howDoWeWork.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/navbar/navbar.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/welcome/welcome.scss";
@import "C:/Users/acrid/Praca/xann/bebok34_2020/src/modules/whatWeDo/whatWeDo.scss";
@import 'shame';
