/**
 * Welcome
 */

.welcome {
  position: relative;

  &:before {
    @include pseudo;
    background-image: url(../assets/img/oval-top.svg);
    background-repeat: no-repeat;
    width: 1007px;
    height: 793px;
    top: 0;
    right: 0;
  }
  
  & p {
    font-size: rem(18);
    line-height: 1.89;

  }
}

.welcome__chart {
  position: relative;


  &-svg {
    position: absolute;
    // background-image: url(../assets/img/chart.svg);
    // background-repeat: no-repeat;
    width: 878px;
    height: 416px;
    top: -50px;
    left: 100px;
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .welcome {
    padding-top: rem(100);
    padding-bottom: rem(140);

    & p {
      margin-bottom: rem(32);
    }
  }
}

@include media-breakpoint-between(md, xl) {
  .welcome {
    padding-top: rem(300);
    padding-bottom: rem(240);

    & .heading,
    & p {
      position: relative;
      z-index: 3;
    }
  }

  .welcome__chart {
    
    &-svg {
      left: -70px;
      display: block;
    }
  }  
}

@include media-breakpoint-up(hd) {
  .welcome {
    padding-top: rem(300);
    padding-bottom: rem(240);
  }

  .welcome .heading {
    margin-bottom: rem(41);
  }

  .welcome p {
    margin-bottom: rem(63);
  }

  .welcome__chart {
    
    &-svg {
      display: block;
    }
  }
}
