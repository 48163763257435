input,
textarea {
  border-radius: 38px;
  background-color: #f6f9fb;
  border: none;
  width: 100%;
  outline: none;
}

input {
  height: 55px;
  padding: 0 rem(16);
  margin-bottom: rem(18);

  & + .js-validate-error-label {
    margin-top: -#{rem(10)};
  }
}

textarea {
  padding: 1rem;
  min-height: 168px;
  resize: none;
  margin-bottom: rem(37);

  & + .js-validate-error-label {
    margin-top: -#{rem(30)};
  }
}

label {
  line-height: 1.44;
  display: block;
  margin-bottom: rem(5);
}


@include media-breakpoint-up(hd) {
  .form-group {
    display: flex;
    justify-content: space-between;

    & div {
      width: 48%;
    }
  }
}
