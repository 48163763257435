/**
 * Visibility
 */

.is-hidden {
  display: none !important;
}
.is-visible {
  display: block !important;
}
